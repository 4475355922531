import React from "react";
import Layout from "../../components/layout/Layout";
import PasswordContent from "../../components/PasswordContent.tsx";
import { Script } from "gatsby";
import Clickmagic from "../../components/common/ClickMagic/Clickmagic";

const ResetPassword = () => {

	return (
		<Layout>
			<PasswordContent />
		</Layout>
	);

};

export const Head = (props: any) => {
	return (
		<>
			<title>Reset Password</title>
			<meta name="robots" content="index, follow"></meta>
			<link rel="canonical" href="https://spartanpeptides.com/reset-password" data-gatsby-head="true"></link>
		</>
	);
};

export default ResetPassword;