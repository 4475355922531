import axios from "axios";

export const checkUser = (userData:any) => {
	const data = userData;
	const TOKEN_URL = process.env.GATSBY_SPARTAN_FORGET_PASSWORD as string;

    return new Promise(async (resolve, reject) => {
        try {
          const response = await axios.post(TOKEN_URL, data);
    
          if (response.data.message === 'Username does not exists') {
            return reject({
              msg: "Username does not exist",
            });
          }
          else if (response.data.message === 'Email-id does not exists') {
            return reject({
              msg: "Email-id does not exist",
            });
          }
          else {
            return resolve({
              msg: "Token created successfully.",
              data: response.data,
            });
          }
        } catch (error: any) {
          return reject({
            msg: error.message,
          });
        }
      });
};

export const checkNewUser = (userData:any) => {
	const data = userData;
	const TOKEN_URL = process.env.GATSBY_SPARTAN_NEW_PASSWORD as string;
  // console.log(TOKEN_URL);
    return new Promise(async (resolve, reject) => {
        try {
          const response = await axios.post(TOKEN_URL, data);
          
    
          if (response.data.message === 'Username does not exists') {
            return reject({
              msg: "Username does not exist",
            });
          }
          else if (response.data.message === 'Email-id does not exists') {
            return reject({
              msg: "Email-id does not exist",
            });
          }
          else {
            return resolve({
              msg: "Token created successfully.",
              data: response.data,
            });
          }
        } catch (error: any) {
          return reject({
            msg: error.message,
          });
        }
      });
};

export const checkToken = (tokenData:any) => {
	const data = tokenData;
	const TOKEN_URL = process.env.GATSBY_SPARTAN_VALIDATE_TOKEN as string;

    return new Promise(async (resolve, reject) => {
        try {
          const response = await axios.post(TOKEN_URL, data);
    
          if (response.data.message === 'Token is expired') {
            return reject({
              msg: "Token is expired",
            });
          }
          else if (response.data.message === 'Token is invalid') {
            return reject({
              msg: "Token is invalid",
            });
          }
          else if (response.data.message === 'Invalid user') {
            return reject({
              msg: "Invalid user",
            });
          }
          else if (response.data.message === 'Malformed Token') {
            return reject({
              msg: "Malformed Token",
            });
          }
          else if (response.data.message === 'Token URL is invalid') {
            return reject({
              msg: "Token URL is invalid",
            });
          }
          else {
            return resolve({
              msg: "Token is valid",
              data: response.data,
            });
          }
        } catch (error: any) {
          return reject({
            msg: error.message,
          });
        }
      });
};

export const updatePass = (passData:any) => {
	const data = passData;
	const TOKEN_URL = process.env.GATSBY_SPARTAN_RESET_PASSWORD as string;

    return new Promise(async (resolve, reject) => {
        try {
          const response = await axios.post(TOKEN_URL, data);
    
          if (response.data.status === false) {
            return reject({
              msg: response.data.message || "Password updation failed",
            });
          }
          else {
            return resolve({
              msg: "Password Updated",
              data: response.data,
            });
          }
        } catch (error: any) {
          return reject({
            msg: error.message,
          });
        }
      });
};